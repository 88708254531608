<template>
  <el-dialog
    v-model="dialogVisible"
    title="New Timesheet"
    width="628px"
    :close-on-click-modal="false"
    :before-close="handleClose"
    @open="handleOpen"
    top="40px"
    class="p-10"
  >
    <DetailTask
      ref="refDetailTask"
      :isEdit="false"
      :shift="shift"
      :isTouched="isTouched"
      :dialogVisible="dialogVisible"
      :isEndTimePM="EndTimePM"
      :totalHour="currentUser.userType == USER_TYPE_STAFF ? totalHour : totalHourSuper"
      @changeTouched="onChangeTouched"
      @changeEndTimePM="onChangeEndTimePM"
      @changeStartTimeAM="onChangeStartTimeAM"
      @changeShift= "onChangeShift"
      @changeValidTimeTask="handleChangeValidTimeTask"
      @changeMaxTimeMultipleTasks="handleMaxTimeMultipleTasks"
      @handlePopupOverLap="handlePopupOverLap"
      @handleAllTaskIsValid="handleAllTaskIsValid"
      @handleCheckNightShift="handleCheckNightShift"
      @handleCheckDayShift="handleCheckDayShift"
      @handleCancelSubmit="handleCancelSubmit"
      @handlePopupOverLapLevel="handlePopupOverLapLevel"
    >
    </DetailTask>
    <div class="text-right pt-[20px]">
      <el-button @click="handleClose()" class="btn-cancel">Close</el-button>
      <el-button @click="handleSave()" class="btn-save" >Save</el-button>
      <el-button @click="handleSubmit()" class="btn-submit">Submit</el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="dialogCheckCondition"
    :width="352"
    align-center
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="grid justify-items-center pt-4">
      <div class="mb-[24px]">
        <icon-svg :name="messageConfirm.colorIcon == 'yellow' ? 'warring' : 'btn-fix'" />
      </div>
      <div class="text-xl font-medium mb-[16px]">{{ messageConfirm.type }}</div>
      <p class="text-base font-bold text-center whitespace-pre-line px-3">
        {{ messageConfirm.message }}
      </p>
    </div>
    <template #footer>
      <div class="flex justify-center mt-4 mb-4 ...">
        <el-button
          class="justify-self-center btn-cancel w-[83px] ml-[16px]"
          @click="handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? ' bg-[#ED1C2D]' : 'bg-[#0CAC59]'"
        >
          <span class="font-bold text-[#fff]">{{ messageConfirm.textButtonCancel }}</span>
        </el-button>
        <el-button
          class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px] bg-[#ED1C2D]"
          @click="(dayShift) ? handleSaveTimeSheet() : handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? 'hidden' : ''"
          v-if="onClickSave"
        >
          <el-icon>
            <icon-svg width="10" height="10" :name="'check-mark-circle'" />
          </el-icon>
          <span class="ml-[6px] font-bold">{{ messageConfirm.textButtonConfirm }}</span>
        </el-button>
        <el-button
          class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px] bg-[#ED1C2D]"
          @click="(dayShift) ? handleClickConfirmSubmit() : handleClosePopupConfirm()"
          :class="messageConfirm.textButtonConfirm == 'None' ? 'hidden' : ''"
          v-else
        >
          <el-icon>
            <icon-svg width="10" height="10" :name="'check-mark-circle'" />
          </el-icon>
          <span class="ml-[6px] font-bold">{{ messageConfirm.textButtonConfirm }}</span>
        </el-button>
      </div>
    </template>
  </el-dialog>
  <PopupNightShiftPrompt  :type="'Submitting'" :message="'Your timesheet will be submitted.\nAre you sure?'"
    :lblBtnSubmit="'Yes'" :lblBtnCancel="'No'" :iconPopup="'icon-popup-submit'" :iconBtnName="'check-mark-circle'" :width="400"
    @submit="handlePopupSubmitTimeSheet" @close="handleCloseSubmitTimeSheet" ref="popupConfirmSubmit" >
  </PopupNightShiftPrompt>


  <div class="el-dialog-popup">
    <PopupConfirm
    :type="'Closing'"
    :message="'Your data won’t be saved.\n Are you sure?'"
    :lblBtnSubmit="'Yes'"
    :iconPopup="'warring'"
    :iconBtnName="'check-mark-circle'"
    @submit="handleCloseDialogTask"
    ref="popupConfirmCloseTab"
    ></PopupConfirm>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import DetailTask from "@/components/DetailTask.vue";
import { Actions } from "modules/dashboard/store/types";
import PopupConfirm from "@/components/PopupConfirm.vue";
import PopupNightShiftPrompt from "@/components/PopupNightShiftPrompt.vue";
import { USER_TYPE_STAFF } from "core/constants";
import IconSvg from "@/layouts/IconBase.vue";
import useTaskPopup from "../composables/useTaskPopup";
import dayjs from "dayjs";

interface MessageConfirm {
  message: string;
  textButtonConfirm: string;
  textButtonCancel: string;
  type: string;
  icon: string;
}

export default defineComponent({
  name: "AddNewTask",
  props: {},
  components: {
    DetailTask,
    PopupConfirm,
    PopupNightShiftPrompt,
    IconSvg
  },
  setup(props) {
    const store = useStore();
    const isTouched = ref<boolean>(false);
    const refDetailTask = ref<InstanceType<typeof DetailTask> | null>();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const popupConfirmCloseTab = ref<InstanceType<typeof PopupConfirm>>();
    const popupConfirmSubmit = ref<InstanceType<typeof PopupConfirm>>()
    const popupConfirmSave = ref<InstanceType<typeof PopupConfirm>>();
    const messageValidTime = ref<string>('');
    const allTaskIsValid = ref<boolean>(false);
    const dialogVisible = ref<boolean>(false);

    const {
      totalHour,
      shift,
      totalHourMultipleTasks,
      StartTimeAM,
      EndTimePM,
      popupOverlap,
      nightShift,
      dayShift,
      isOpenPopupAddTask,
      messageConfirm,
      totalHourSuper,
      dialogCheckCondition,
      onClickSave,
      isClickSubmit,
      popupOverlapLevel,
      onChangeEndTimePM,
      onChangeStartTimeAM,
      onChangeShift,
      handleMaxTimeMultipleTasks,
      handlePopupOverLap,
      handleCheckNightShift,
      handleCheckDayShift,
      handleClosePopupConfirm,
      handleSaveCheckAndPopup,
      handleSubmitCheckAndPopup,
      handlePopupOverLapLevel
    } = useTaskPopup()

    const resetDefault = () => {
      shift.value = false
      StartTimeAM.value = 510
      EndTimePM.value = -1
      isClickSubmit.value = false
    }

    const changeNightShiftFlag = () => {
      shift.value = true
      dialogCheckCondition.value = false
      isClickSubmit.value = false
    }

    const handleSave = async () => {
      const isInvalid = handleSaveCheckAndPopup();
      if (isInvalid) {
        return
      }
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.SAVE);
      dialogCheckCondition.value = false
      resetDefault()
    };

    const handleValidateForm = async () => {
      try {
        store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
        await new Promise((f) => setTimeout(f, 200));
        store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.CONFIRM);
        isClickSubmit.value = true
      } catch (error) {
        console.log('error', error)
      }
    }

    const handlePopupSubmitTimeSheet = async () => {
      store.commit('auth/SET_IS_VALID', true);
      popupConfirmSubmit.value.showDialog = false
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.CONFIRM);
      resetDefault()
    }

    const handleSubmit = async () => {
      const isInvalid = handleSubmitCheckAndPopup();
      if (isInvalid) {
        return
      }
      handleValidateForm();
    }

    const handleClose = () => {
      if (isTouched.value) {
        popupConfirmCloseTab.value.showDialog = true
      } else {
        handleCloseDialogTask()
      }
    };

    const handleCloseDialogTask = () => {
      refDetailTask.value.resetData();
      store.commit("SET_OPEN_POPUP_ADD_TASK", false);
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      popupConfirmCloseTab.value.showDialog = false;
      isTouched.value = false
      resetDefault()
      onClickSave.value = false
    };

    const handleOpen = () => {
      const currentDay = dayjs().format("YYYY-MM-DD")
      refDetailTask.value.resetData();
      store.dispatch("dashboard/loadCountTotalTimesheet", { date: currentDay })
    }
    const onChangeTouched = (value: any) => {
      isTouched.value = value
    }

    const handleChangeValidTimeTask = (messageError: string) => {
      if (messageError) {
        messageValidTime.value = messageError
      } else {
        messageValidTime.value = ''
      }
    }

    const handleAllTaskIsValid = (value: boolean) => {
      allTaskIsValid.value = value
    }

    const handleCloseSubmitTimeSheet = () => {
      isClickSubmit.value = false
      allTaskIsValid.value = false
    }

    const handleClickConfirmSubmit = () => {
      dialogCheckCondition.value = false
      handleValidateForm()
    }

    const handleSaveTimeSheet = async () => {
      store.commit("dashboard/SET_ACTION_TIME_SHEET", undefined);
      await new Promise((f) => setTimeout(f, 200));
      store.commit("dashboard/SET_ACTION_TIME_SHEET", Actions.SAVE);
      dialogCheckCondition.value = false
      onClickSave.value = false
    }

    const handleCancelSubmit = (value: boolean) => {
      isClickSubmit.value = false
    }

    watch([allTaskIsValid, isClickSubmit], async () => {
      await new Promise((f) => setTimeout(f, 200));
      if (allTaskIsValid.value && isClickSubmit.value) {
        popupConfirmSubmit.value.showDialog = true
      }
    })

    watch(isOpenPopupAddTask, () => {
      dialogVisible.value = isOpenPopupAddTask.value;
    });

    return {
      isTouched,
      dialogVisible,
      handleClose,
      handleSave,
      refDetailTask,
      handlePopupSubmitTimeSheet,
      handleCloseDialogTask,
      popupConfirmSubmit,
      popupConfirmCloseTab,
      handleOpen,
      onChangeTouched,
      EndTimePM,
      onChangeEndTimePM,
      onChangeShift,
      shift,
      onChangeStartTimeAM,
      popupConfirmSave,
      StartTimeAM,
      handleChangeValidTimeTask,
      messageValidTime,
      handleMaxTimeMultipleTasks,
      totalHourMultipleTasks,
      totalHour,
      changeNightShiftFlag,
      totalHourSuper,
      currentUser,
      USER_TYPE_STAFF,
      handlePopupOverLap,
      popupOverlap,
      handleAllTaskIsValid,
      handleClosePopupConfirm,
      messageConfirm,
      dialogCheckCondition,
      handleSubmit,
      isClickSubmit,
      handleCloseSubmitTimeSheet,
      handleClickConfirmSubmit,
      handleCheckNightShift,
      handleCheckDayShift,
      nightShift,
      dayShift,
      onClickSave,
      handleSaveTimeSheet,
      handleCancelSubmit,
      handlePopupOverLapLevel,
      popupOverlapLevel
    };
  },
});
</script>
<style lang="scss">
.el-dialog__title {
  color: #231F20;
}

.btn-save {
  width: 97px;
}

.btn-submit {
  width: 112px;
}

.createTimeSheet {
  padding: 40px;
}

.dark {
  .el-button.btn-submit {
    background: #246BFD;
    border: none;
    &:hover,
    &:focus {
      background: #246BFD !important;
    }
  }
  .el-button.btn-save {
    background: #0CAC59;
    border: none;

    &:hover,
    &:focus {
      background: #0CAC59 !important;
    }
  }
}
</style>