import { MutationTree } from "vuex";
import { TimesheetApproveState, 
  SET_TIME_SHEETS_APPROVE,
  SET_TIME_SHEETS_FILTER,
  FilterTimeSheet,
  SET_LOADING_TIME_SHEETS,
  SET_TOTAL_HOUR_TIME_SHEET,
  SET_SORT_PARAMS_TIME_SHEET,
} from "./types";

export const mutations: MutationTree<TimesheetApproveState> = {
  [SET_TIME_SHEETS_APPROVE](state: TimesheetApproveState, data: any) {
    state.timeSheetsApprove = data.lstTimeSheets;
    state.totalRowFetchTimeSheet = data.count;
  },
  [SET_TIME_SHEETS_FILTER](state: TimesheetApproveState, data: FilterTimeSheet) {
    state.filters = data
  },
  [SET_LOADING_TIME_SHEETS](state: TimesheetApproveState, status: boolean) {
    state.loading = status
  },
  [SET_TOTAL_HOUR_TIME_SHEET](state: TimesheetApproveState, data: number) {
    state.totalHour = data;
  },
  [SET_SORT_PARAMS_TIME_SHEET](state: TimesheetApproveState, data: string) {
    state.sortParams = data;
  },
};
