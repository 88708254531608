import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './index.css'
import "./styles/index.scss";
import "./styles/element/index.scss";
import { msalPlugin } from "./plugins/msalPlugin";
import { messaging } from './firebase/firebase';
import { msalInstance } from "./authConfig";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from 'router/navigationClient'
import Vue3Signature from "vue3-signature"
import {NotificationService} from "modules/notifications/service";
import {AuthService} from "modules/auth/service";
const service = new AuthService()
// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
});

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(Vue3Signature as any)
app.config.warnHandler = function (msg, vm, trace) {
  return null
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered:', registration);
      messaging.useServiceWorker(registration);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

// app.use(msalPlugin, msalInstance);
router.isReady().then(() => {
    // For develop
    // const token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2VtZWNvdGltZXNoZWV0cy5iMmNsb2dpbi5jb20vNDYyYzZiOTItZDhmMi00OWZlLWJkNzUtNmUyYmFkZDg5MDFiL3YyLjAvIiwiZXhwIjoxNjc4MTIwMTIwLCJuYmYiOjE2NzgxMTI5MjAsImF1ZCI6ImI2YzM0MmNiLTE1NmQtNGE5Zi05ODdhLTJmYjk4ODg4ODIyZCIsIm9pZCI6ImJkM2Q4ODVkLTQ4YWQtNDBiNi04YTQ0LWUyYTkwMGE3MmVlYyIsInN1YiI6ImJkM2Q4ODVkLTQ4YWQtNDBiNi04YTQ0LWUyYTkwMGE3MmVlYyIsInRmcCI6IkIyQ18xX1NpZ25JbiIsIm5vbmNlIjoiZDVjZjcwODctY2VmMy00MzM0LWI1MDMtM2RiMDFlODEyMzMxIiwic2NwIjoiU3lzdGVtLkFkbWluIFVzZXIuTWFuYWdlIFRpbWVzaGVldC5SZXZpZXcgVGltZXNoZWV0LkFwcHJvdmUgVGltZXNoZWV0LlN1Ym1pdCIsImF6cCI6IjIyM2FlNTRhLWZhZGUtNGVhYS04N2NiLWI1OTg2NTA5OTBkYSIsInZlciI6IjEuMCIsImlhdCI6MTY3ODExMjkyMH0.HUtFj6BYNo6-pi-D48NV72IozwRF8vPFsTlaNiBxBXm4_ySXcQzJMZAH6PI2M-gyoViqPKJnNzXTIrYsgVF3PyiWnc_50uFY1ryZiuDw65QCnmNVQwc0IAIK_pQaBe1rhNddYbLP6deo1TjqoTSuEfNbgGim7H3x0O_t78IKnmH-HEDKQ59X3oz3_3-3uFg2Aqk4svw2D5DGvta6HViMv8jjLyiAzEI6eWe29f9RygN_0vVXws5wyPys2CIgeKVIVPgexq-bPJGB_VIfqoSbe9pEnmi7mjWmXX_1NzRDAo7x_gzSjc5arG8rK5azHajbMxRbI0uKS-Wkoaa8IpHYZw'
    // localStorage.setItem('access-token', token);


    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    messaging.requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        return messaging.getToken();
      })
      .then(async (token) => {
        const notificationSvc = new NotificationService();

        const currentUser = await service.me()
        await notificationSvc.registerToken({
          "DeviceId": currentUser.userId?.toString(),
          "Token": token,
          "Platform": "web"
        })
        // Send the token to your server or store it for later use
      })
      .catch((error) => {
        console.error('Error requesting permission:', error);
      });
    app.mount('#app')
});
