<template>
  <el-dialog
    v-model="showDialog"
    :width="width"
    align-center
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="grid justify-items-center pt-4">
      <div class="mb-[24px] icon-warring" v-if="lblBtnCancel == 'Fix'">
        <svg
          width="68"
          height="68"
          viewBox="0 0 68 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="68"
            height="68"
            rx="16"
            fill="#ED1C2D"
            fill-opacity="0.2"
          />
          <path
            d="M31.8197 16.5488C33.8975 15.4286 36.4915 16.0795 37.7661 17.9886L37.9316 18.2567L53.437 45.5032C53.8063 46.1522 54 46.8818 54 47.6237C54 49.9399 52.1512 51.8359 49.8115 51.9899L49.5036 52H18.4972C17.7352 52 16.9857 51.8115 16.3191 51.4522C14.2411 50.3323 13.4316 47.8469 14.4179 45.7824L14.5636 45.5037L30.0647 18.2571C30.4734 17.5387 31.0816 16.9467 31.8197 16.5488ZM34.0018 42.2614C32.8996 42.2614 32.0061 43.1311 32.0061 44.2039C32.0061 45.2766 32.8996 46.1463 34.0018 46.1463C35.104 46.1463 35.9975 45.2766 35.9975 44.2039C35.9975 43.1311 35.104 42.2614 34.0018 42.2614ZM33.9975 26.6939C32.9726 26.6943 32.1283 27.4454 32.0132 28.4128L31.9998 28.6396L32.0034 38.3665L32.0169 38.5934C32.1327 39.5607 32.9777 40.3112 34.0025 40.3109C35.0274 40.3105 35.8717 39.5593 35.9868 38.592L36.0002 38.3651L35.9966 28.6382L35.9831 28.4114C35.8673 27.4441 35.0223 26.6936 33.9975 26.6939Z"
            fill="#ED1C2D"
          />
        </svg>
      </div>
      <div class="mb-[24px]" v-else>
        <icon-svg :name="iconPopup" />
      </div>
      <div class="text-xl font-medium mb-[16px]">{{ type }}</div>
      <p
        class="text-base font-bold"
        style="text-align: center; white-space: pre-line; padding: 0 10px"
      >
        {{ message }}
      </p>
    </div>
    <template #footer>
      <div class="flex justify-center mt-4 mb-4 ...">
        <el-button
          :class="message.length == 45 || message.length > 100 ? 'bg-[#0CAC59]' : ''"
          class="justify-self-center btn-cancel w-[83px] ml-[16px]"
          @click="handleClose"
        >
          <span
            class="font-bold"
            :class="message.length == 45 || message.length > 100 ? 'text-[#fff]' : ''"
            >{{ lblBtnCancel }}</span
          >
        </el-button>
        <el-button
          v-if="lblBtnSubmit"
          :class="message.length == 45 || message.length > 100 ? 'bg-[#ED1C2D]' : ''"
          class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px]"
          @click="handleSubmit"
        >
          <el-icon>
            <icon-svg width="10" height="10" :name="iconBtnName" />
          </el-icon>
          <span class="ml-[6px] font-bold">{{ lblBtnSubmit }}</span>
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import IconSvg from "@/layouts/IconBase.vue";
export default defineComponent({
  name: "IconCalendar",
  props: {
    type: String,
    message: String,
    lblBtnSubmit: String,
    lblBtnCancel: String,
    iconBtnName: String,
    iconPopup: String,
    width: Number,
  },
  components: {
    IconSvg,
  },
  setup(props, ctx) {
    const type = ref<string>(props.type!);
    const message = ref<string>(props.message!);
    const lblBtnSubmit = ref<string>(props.lblBtnSubmit!);
    const lblBtnCancel = ref<string>(props.lblBtnCancel!);
    const iconBtnName = ref<string>(props.iconBtnName!);
    const iconPopup = ref<string>(props.iconPopup!);
    const showDialog = ref<boolean>(false);
    const reason = ref<string>("");
    const width = ref<number>(props.width == undefined ? 352 : props.width);
    const handleSubmit = () => {
      ctx.emit("submit");
    };

    const handleDecline = () => {
      ctx.emit("submit", { reason: reason.value });
    };

    const handleClose = () => {
      ctx.emit("close");
      showDialog.value = false
    }

    return {
      type,
      showDialog,
      handleSubmit,
      reason,
      handleDecline,
      message,
      lblBtnSubmit,
      lblBtnCancel,
      iconBtnName,
      iconPopup,
      width,
      handleClose
    };
  },
});
</script>

<style lang="scss">
</style>
