import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from "core/constants";
import { RootState } from "store";
import { ActionTree } from "vuex";
import { AuthService } from "../service";
import { AuthState, CurrentUserModel, SET_USER } from "./types";
const service = new AuthService()
export const actions: ActionTree<AuthState, RootState> = {
  actClearToken({ commit }) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  },
  actSetToken({ commit }, params: any) {
    if(params.rememberMe) {
      localStorage.setItem(AUTH_TOKEN_KEY, params.token);
    }
    else {
      sessionStorage.setItem(AUTH_TOKEN_KEY, params.token);
    }
  },
  actSetRefreshToken({ commit }, token: string) {
    document.cookie = `${REFRESH_TOKEN_KEY}=${token}`;
  },
  async actGetMe({commit}){
    try {
      const currentUser = await service.me()
      commit(SET_USER, currentUser)
      return currentUser
    } catch (error) {
      return null
    }
  },
  async actLogin({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.login(params, options);
      return data;
    }
  },
  async actForgotPassword({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.forgotPassword(params, options);
      return data;
    }
  },
  async actVerifyCode({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.verifyCode(params, options);
      return data;
    }
  },
  async actResetPassword({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.resetPassword(params, options);
      return data;
    }
  },
  async actResendCode({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.resendCode(params, options);
      return data;
    }
  },
  async actRefreshToken({ commit }, params: any, options?: any) {
    if(params){
      const data = await service.refreshToken(params, options);
      return data;
    }
  },
};
