import Service from "core/service";
export class TimeSheetApproveService extends Service {
  fetchTimeSheet(
    currentPage: number,
    pageSize: number,
    expand?: string,
    filter?: string,
    orderby?: string
  ) {
    let params: any = {
      $orderby: "CreatedOn desc",
    };
    if (expand) {
      params["$expand"] = expand;
    }
    if (filter) {
      params["$filter"] = filter;
    }
    if (orderby) {
      params["$orderby"] = orderby;
    }
    params["$count"] = true;
    params["$top"] = pageSize;
    params["$skip"] = currentPage == 1 ? 0 : (currentPage - 1) * pageSize;
    return this.get("/Timesheet", params);
  }

  fetchTimeSheetById(id: number, expand?: string) {
    let params: any = {};
    if (expand) {
      params["$expand"] = expand;
    }
    return this.get(`/Timesheet(${id})`, params);
  }

  updateTimeSheet(dataUpdate: any) {
    return this.patch(`/Timesheet(${dataUpdate.Id})`, dataUpdate);
  }

  approveTimeSheets(ids: any) {
    return this.post(`/Timesheet/Approvals`, { ids });
  }

  fetchUserApprove(filter?: string) {
    let params: any = {};
    if (filter) {
      params["$filter"] = filter;
    }
    return this.get("/User", params);
  }

  updateApprovalGroup(TimesheetHeaderId: number, approvalGroupId: number) {
    return this.patch(`/Timesheet/${TimesheetHeaderId}/approval-groups/${approvalGroupId}`);
  }
}
