import { RootState } from "store";
import { ActionTree } from "vuex";
import { DashboardService } from "../service";
import {
    DashboardState,
    FilterTimeSheet,
    SET_COUNT_NOTI_UNREAD,
    SET_COUNT_TIME_SHEET_STATUS,
    SET_LIST_TIME_SHEET, SET_LIST_TIME_SHEET_EVENT,
    timeSheetStatusName,
    SET_TOTAL_HOUR_TIME_SHEET,
    SET_LIST_TIME_SHEET_LINE
} from "./types";
import dayjs from "dayjs";
import { NotificationService } from "modules/notifications/service";
import { SET_USER } from "modules/auth/store/types";


const service = new DashboardService();
const notificationService = new NotificationService();
export const actions: ActionTree<DashboardState, RootState> = {
    async actCreateTimeSheet({ commit }, params: any) {
        return service.createTimeSheet(params)
    },

    async actLoadTimeSheet({ commit }, params: any) {
        const filterTimeSheet: FilterTimeSheet = this.getters["dashboard/filterTimeSheet"];
        let filterExpandLine = ""
        // if (filterTimeSheet.divisionJob) {
        //     filterExpandLine = `$filter=(TimesheetLineType eq 2 and (JobCostCode eq null or JobCostCode/Job/Division eq '${filterTimeSheet.divisionJob}')) or TimesheetLineType ne 2;`
        // }
        const expand = `TimesheetLines(${filterExpandLine}$expand=WorkOrder,JobCostCode($expand=Job),GLAccount,WorkOrderTask,TimesheetAdditionals($select=Manufacturer)),Approver`
        let filterStr = []
        const lstTimeSheet = this.getters["dashboard/timeSheets"]
        if (filterTimeSheet.timeSheetId) {
            filterStr.push(`startswith(cast(TimesheetHeaderId, 'Edm.String'),'${filterTimeSheet.timeSheetId.toString()}')`)
        }
        if (filterTimeSheet.fromDay && filterTimeSheet.toDay) {
            filterStr.push(`StartDate ge ${filterTimeSheet.fromDay} and StartDate le ${filterTimeSheet.toDay}`);
        }
        if (filterTimeSheet.timeSheetStatus || filterTimeSheet.timeSheetStatus == 0) {
            filterStr.push(`TimesheetStatus eq ${filterTimeSheet.timeSheetStatus}`);
        }

        // if (filterTimeSheet.divisionJob) {
        //     filterStr.push(`TimesheetLines/any(line: (line/TimesheetLineType eq 2 and (line/JobCostCode eq null or line/JobCostCode/Job/Division eq '${filterTimeSheet.divisionJob}')) or line/TimesheetLineType ne 2)`)
        // }

        const result = await service.fetchAllTimesheet(expand, filterStr.join(' and '), params)
        const data = result.map((item: TimeSheetHeader) => {
            const timeSheet: TimeSheetHeader = {
                ...item
            }
            if (timeSheet.StartDate) {
                timeSheet.StartDate = dayjs(timeSheet.StartDate).format('MMM DD, YYYY')
            }
            timeSheet.TimesheetLines = []
            if (timeSheet.TimesheetStatus || timeSheet.TimesheetStatus === 0) {
                timeSheet.TimesheetStatusName = timeSheetStatusName[timeSheet.TimesheetStatus as keyof typeof timeSheetStatusName]
            }
            if (item.TimesheetLines && item.TimesheetLines.length > 0) {
                item.TimesheetLines.forEach((line) => {

                    const timesheetLine: TimeSheetLine = {
                        ...line
                    }
                    timesheetLine.JobTitle = '---'

                    if (line.TimesheetLineType === 0) {
                        timesheetLine.JobType = 'Rental'
                    } else if (line.TimesheetLineType === 1) {
                        timesheetLine.JobType = 'Unbill'
                    } else if (line.TimesheetLineType === 2) {
                        timesheetLine.JobType = 'Job cost'
                    }

                    if (line.WorkOrderTaskId && line.WorkOrderTask) {
                        timesheetLine.JobTitle = `${line.WorkOrderTask.Name} ${line.WorkOrderTask.Description}`
                    }
                    if (line.JobCostCodeId && line.JobCostCode) {
                        timesheetLine.JobTitle = `${line.JobCostCode.Name} ${line.JobCostCode.Description}`
                    }
                    if (line.Glaccount && line.GlaccountId) {
                        timesheetLine.JobTitle = `${line.Glaccount.Description}`
                    }
                    if (line.TimesheetLineMinutes && line.TimesheetLineMinutes > 0) {
                        timesheetLine.TimesheetLineMinutesTitle = `${Math.floor(line.TimesheetLineMinutes / 60)}h ${line.TimesheetLineMinutes % 60}min`
                    }
                    timeSheet.TimesheetLines?.push(timesheetLine)
                })
            }
            if (timeSheet.TotalDuration > 0) {
                timeSheet.TotalWorkingTitle = `${Math.floor(timeSheet.TotalDuration / 60)}h ${timeSheet.TotalDuration % 60}min`
                timeSheet.TotalWorkingHours = timeSheet.TotalDuration / 60
            } else {
                timeSheet.TotalWorkingTitle = '0h'
                timeSheet.TotalWorkingHours = 0
            }
            return timeSheet;
        })
        commit(SET_LIST_TIME_SHEET, [...lstTimeSheet, ...data])
    },

    async actCountTimeSheetStatus({ commit }, params: any) {
        let filterStr = undefined
        const filterTimeSheet: FilterTimeSheet = this.getters["dashboard/filterTimeSheet"]
        if (filterTimeSheet.timeSheetId) {
            filterStr = `startswith(cast(TimesheetHeaderId, 'Edm.String'),'${filterTimeSheet.timeSheetId.toString()}')`;
        }
        if (filterTimeSheet.fromDay && filterTimeSheet.toDay) {
            filterStr = filterStr ? filterStr + ` and StartDate ge ${filterTimeSheet.fromDay} and StartDate le ${filterTimeSheet.toDay}` : `StartDate ge ${filterTimeSheet.fromDay} and StartDate le ${filterTimeSheet.toDay}`;
        }
        // if (filterTimeSheet.divisionJob) {
        //     const filterDivisionJob = `TimesheetLines/any(line: (line/TimesheetLineType eq 2 and (line/JobCostCode eq null or line/JobCostCode/Job/Division eq '${filterTimeSheet.divisionJob}')) or line/TimesheetLineType ne 2)`
        //     filterStr = filterStr ? filterStr + `and (${filterDivisionJob})` : `(${filterDivisionJob})`
        // }
        const promise = []
        promise.push(service.countTimeSheetByStatus(filterStr).then((res: any) => res['@odata.count'])) // count all
        promise.push(service.countTimeSheetByStatus(`TimesheetStatus eq 0 ${filterStr ? 'and ' + filterStr : ''}`).then((res: any) => res['@odata.count'])) // count Draft
        promise.push(service.countTimeSheetByStatus(`TimesheetStatus eq 1 ${filterStr ? 'and ' + filterStr : ''}`).then((res: any) => res['@odata.count'])) // count Pending
        promise.push(service.countTimeSheetByStatus(`TimesheetStatus eq 2 ${filterStr ? 'and ' + filterStr : ''}`).then((res: any) => res['@odata.count'])) // count Approve
        promise.push(service.countTimeSheetByStatus(`TimesheetStatus eq 3 ${filterStr ? 'and ' + filterStr : ''}`).then((res: any) => res['@odata.count'])) // count Declined
        promise.push(service.countTimeSheetByStatus(`TimesheetStatus eq 5 ${filterStr ? 'and ' + filterStr : ''}`).then((res: any) => res['@odata.count'])) // count Cancelled
        const [total, totalDraft, totalPendingApproved, totalApproved, totalDeclined, totalCanceled] = await Promise.all(promise)
        const data = {
            total,
            totalDraft,
            totalPendingApproved,
            totalApproved,
            totalDeclined,
            totalCanceled,
        }
        commit(SET_COUNT_TIME_SHEET_STATUS, data)
        return data
    },

    async actGetTimeSheet({ commit }, params: any) {
        const expand = `TimesheetLines($expand=JobCostCode,GLAccount,WorkOrderTask,
            TimesheetAdditionals(select=TimesheetAdditionalId,Manufacturer,Model,Smureading,SerialNumber,CustomerName,CustomerEmail,Locations,TravelKms,
                Rego,WorkerSignature,CustomerSignature,AdditionalInfo,CustomerAcceptanceName))
        ,Approver,User`
        const select = `EndTime,Shift,StartDate,StartTime,SubmittedCount,TimesheetHeaderId,TimesheetStatus,UserId`
        const result = await service.fetchTimeSheetById(params.id, expand, select)
        return result
    },

    async actGetTimeSheetEvent({ commit }, params: any) {
        const countNotiUnRead = await notificationService.fetchCountNotiUnRead()
        const result = await notificationService.fetchNotification(1, 1000000)
        commit(SET_LIST_TIME_SHEET_EVENT, result)
        commit(SET_COUNT_NOTI_UNREAD, countNotiUnRead)
        return result
    },

    async actUpdateTimeSheet({ commit }, params: any) {
        const result = await service.updateTimeSheet(params)
        return result
    },

    async actFetchMessage({ commit }, params: any) {
        const filters = `TimesheetHeaderId eq ${params.TimesheetHeaderId}`
        const expand = `TimesheetEventNotifications(expand=User(select=FirstName,LastName,Email,AvatarUrl))`
        const result = await service.fetchTimesheetEvent(expand, filters)
        const messageModel: MessageEventModel[] = []
        if (result.value) {
            result.value.forEach((timesheetEvent: TimesheetEvent) => {
                if (timesheetEvent.TimesheetEventNotifications) {
                    timesheetEvent.TimesheetEventNotifications.forEach((timesheetEventNotification) => {
                        messageModel.push({
                            TimesheetEventDateTime: timesheetEventNotification.TimesheetEventNotificationDateTime,
                            TimesheetEventId: timesheetEvent.TimesheetEventId,
                            TimesheetEventType: timesheetEvent.TimesheetEventType,
                            User: timesheetEventNotification.User,
                            Message: timesheetEventNotification.Message,
                            TimesheetEventNotificationId: timesheetEventNotification.TimesheetEventNotificationId
                        })
                    })
                }
            });
        }
        return messageModel
    },

    async uploadSignature({ commit }, params: any) {
        const data = await service.uploadSignature(params);
        return data;
    },
    async getBase64FromUrl({ commit }, params: any) {
        const data = await service.getBase64FromUrl(params);
        return data;
    },

    async loadCountTotalTimesheet({ commit }, params: any) {
        let filterStr = ''
        let totalHour = 0
        filterStr = (`StartDate ge ${params.date} and StartDate le ${params.date} and TimesheetStatus eq 1 or StartDate ge ${params.date} and StartDate le ${params.date} and TimesheetStatus eq 2`);
        const selectProps = 'TotalDuration,UserId,TimesheetHeaderId'
        const expand = `TimesheetLines($select=StartTime,EndTime)`
        let currentUser = this.getters["auth/currentUser"];

        const data = await service.fetchTotalTimesheet(selectProps, expand, filterStr)
        
        const dataByUserId = data.value.filter((item: any) => params?.userId ? item.UserId == params?.userId : item.UserId == currentUser.userId)
        const checkCurrentData = dataByUserId.filter((item: any) => item?.TimesheetHeaderId  !== params?.timesheetId)

        if (checkCurrentData.length > 0) {
            totalHour = checkCurrentData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.TotalDuration, 0);
        }
        commit(SET_TOTAL_HOUR_TIME_SHEET, totalHour);
        commit(SET_LIST_TIME_SHEET_LINE, checkCurrentData);
    },

};
