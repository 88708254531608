import { ref, computed } from 'vue'
import { useStore } from "vuex";

interface MessageConfirm {
  message: string;
  textButtonConfirm: string;
  textButtonCancel: string;
  type: string;
  icon: string;
  colorIcon: string;
}

export default function useTaskPopup() {
  const store = useStore();
  const dialogVisibleEditTask = ref<boolean>(false);
  const totalHour = computed(() => store.getters["dashboard/totalHour"]);
  const shift = ref<boolean>(false)
  const totalHourMultipleTasks = ref<number>(0);
  const StartTimeAM = ref<number>(510);
  const EndTimePM = ref<number>(-1);
  const popupOverlap = ref<boolean>(false);
  const popupOverlapLevel = ref<boolean>(false);
  const nightShift = ref<boolean>(false)
  const dayShift = ref<boolean>(false)

  const isOpenPopupAddTask = computed(() => store.state.isOpenPopupAddTask);
  const totalHourSuper = computed(() => store.getters['timeSheetsApprove/totalHour']);
  const isClickSubmit = ref<boolean>(false);
  const dialogCheckCondition = ref<boolean>(false);
  const onClickSave = ref<boolean>(false);
  const passAllTaskIsValid = ref<boolean>(false);
  const isAllowEdit = ref<boolean>(false);
  const settings = computed(() => store.getters['setting/settings']);

  const messageConfirm = ref<MessageConfirm>({
    message: 'Your timesheet will be submitted.\nAre you sure?',
    textButtonConfirm: 'Yes',
    textButtonCancel: 'No',
    type: 'Confirm',
    icon: 'btn-fix',
    colorIcon: 'yellow'
  });

  const onChangeEndTimePM = (value: any) => {
    EndTimePM.value = value
  }
  const onChangeStartTimeAM = (value: any) => {
    StartTimeAM.value = value
  }
  const onChangeShift = (value: boolean) => {
    shift.value = value
  }

  const handleAllowEdit = (value: boolean) => {
    isAllowEdit.value = value
  }

  const handleMaxTimeMultipleTasks = (value: number) => {
    totalHourMultipleTasks.value = value
  }

  const handlePopupOverLap = (error: boolean) => {
    if (error) {
      popupOverlap.value = true
    } else {
      popupOverlap.value = false
    }
  }
  
  const handlePopupOverLapLevel = (error: boolean) => {
    if (error) {
      popupOverlapLevel.value = true
    } else {
      popupOverlapLevel.value = false
    }
  }

  const handleCheckNightShift = (value: boolean) => {
    nightShift.value = value
  }

  const handleCheckDayShift = (value: boolean) => {
    dayShift.value = value
  }

  const handleClosePopupConfirm = () => {
    dialogCheckCondition.value = false
    isClickSubmit.value = false
    onClickSave.value = false
  }

  const checkNightShiftMismatchWarning = (textType: string, textIcon: string, colorIcon: string) => {
    if (settings.value.PassMidnightCheckingEnable) {
      if (nightShift.value) {
        return {
          message: "You have entered time for a Day shift that\n passes midnight. Please change the\n shift type to 'Night Shift' or correct the\n task time before saving or submitting.",
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon,
          colorIcon
        }
      }
    }
  }
  const checkDayShiftMismatchWarning = (textType: string, textIcon: string, colorIcon: string) => {
    if (dayShift.value) {
      return {
        message: 'You have entered time for a Night shift \nthat passes midday.\n Please confirm this is accurate \nor go back and correct the timesheet.',
        textButtonConfirm: 'Confirm',
        textButtonCancel: 'Fix',
        type: textType,
        icon: textIcon,
        colorIcon
      }
    }
  }
  const checkCurrentTimesheetTotalHours = (textType: string, textIcon: string, colorIcon: string) => {
    if (settings.value.MaximumDailyHoursCheckingEnable) {
      if (totalHourMultipleTasks.value > (settings.value.MaximumDailyHours * 60)) {
        return {
          message: `Total hours logged in the timesheet\n is over ${settings.value.MaximumDailyHours} hours.\n Please review and adjust your tasks\n accordingly.`,
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon,
          colorIcon
        }
      }
    }
  }
  const checkOverlapWarning = (textType: string, textIcon: string, colorIcon: string) => {
    if (popupOverlap.value) {
      return {
        message: 'Overlapping tasks detected in the\n timesheet. Ensure there are no conflicts\n in your specified task times.',
        textButtonConfirm: 'None',
        textButtonCancel: 'Fix',
        type: textType,
        icon: textIcon,
        colorIcon
      }
    }
  }

  const checkTimesheetTotalHours = (textType: string, textIcon: string, colorIcon: string) => {
    if (settings.value.MaximumDailyHoursCheckingEnable) {
      if ((totalHourMultipleTasks.value + totalHour.value) > (settings.value.MaximumDailyHours * 60)) {
        return {
          message: `The combined total hours for all\n timesheets on this day exceed ${settings.value.MaximumDailyHours} hours.\n Please review and adjust your\n timesheets.`,
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon,
          colorIcon
        }
      }
    }
  }
  
  const checkOverlapWarningLevel = (textType: string, textIcon: string, colorIcon: string) => {
    if (settings.value.TimesheetOverlapCheckingEnable) {
      if (popupOverlapLevel.value) {
        return {
          message: "Overlapping tasks detected with\n another timesheet. Ensure no conflicts in\n your specified task times with other\n timesheet's task times",
          textButtonConfirm: 'None',
          textButtonCancel: 'Fix',
          type: textType,
          icon: textIcon,
          colorIcon
        }
      }
    }
  }

  const handleSaveCheckAndPopup = () => {
    onClickSave.value = true
    const checktotalHour = checkCurrentTimesheetTotalHours('Confirm', 'btn-fix', 'red');
    if (checktotalHour) {
      messageConfirm.value = checktotalHour
      dialogCheckCondition.value = true;
      return true
    }

    const checkOverlap = checkOverlapWarning('Confirm', 'btn-fix', 'red');
    if (checkOverlap) {
      messageConfirm.value = checkOverlap
      dialogCheckCondition.value = true;
      return true
    }

    const checkNight = checkNightShiftMismatchWarning('Confirm', 'btn-fix', 'red');
    if (checkNight) {
      messageConfirm.value = checkNight
      dialogCheckCondition.value = true;
      return true
    }    

    const checkDay = checkDayShiftMismatchWarning('Confirm', 'btn-fix', 'yellow');
    if (checkDay) {
      messageConfirm.value = checkDay;
      dialogCheckCondition.value = true;
      return true
    }
  }

  const handleSubmitCheckAndPopup = () => {
    const checkCurrentHour = checkCurrentTimesheetTotalHours('Submitting', 'check-mark-circle', 'red');
    if (checkCurrentHour) {
      messageConfirm.value = checkCurrentHour
      dialogCheckCondition.value = true;
      return true
    }

    const checkTotalHours = checkTimesheetTotalHours('Submitting', 'check-mark-circle', 'red');
    if (checkTotalHours) {
      messageConfirm.value = checkTotalHours
      dialogCheckCondition.value = true;
      return true
    }

    const checkOverlap = checkOverlapWarning('Submitting', 'check-mark-circle', 'red');
    if (checkOverlap) {
      messageConfirm.value = checkOverlap
      dialogCheckCondition.value = true;
      return true
    }

    const checkOverlapLevel = checkOverlapWarningLevel('Submitting', 'check-mark-circle', 'red');
    if (checkOverlapLevel) {
      messageConfirm.value = checkOverlapLevel;
      dialogCheckCondition.value = true;
      return true
    }

    const checkNight = checkNightShiftMismatchWarning('Submitting', 'check-mark-circle', 'red');
    if (checkNight) {
      messageConfirm.value = checkNight
      dialogCheckCondition.value = true;
      return true
    }

    const checkDay = checkDayShiftMismatchWarning('Submitting', 'check-mark-circle', 'yellow');
    if (checkDay) {
      messageConfirm.value = checkDay;
      dialogCheckCondition.value = true;
      return true
    }
    
  }

  return {
    dialogVisibleEditTask,
    totalHour,
    shift,
    totalHourMultipleTasks,
    StartTimeAM,
    EndTimePM,
    popupOverlap,
    nightShift,
    dayShift,
    isOpenPopupAddTask,
    messageConfirm,
    totalHourSuper,
    isClickSubmit,
    dialogCheckCondition,
    onClickSave,
    passAllTaskIsValid,
    isAllowEdit,
    popupOverlapLevel,
    onChangeEndTimePM,
    onChangeStartTimeAM,
    onChangeShift,
    handleMaxTimeMultipleTasks,
    handlePopupOverLap,
    handleCheckNightShift,
    handleCheckDayShift,
    checkNightShiftMismatchWarning,
    checkDayShiftMismatchWarning,
    checkCurrentTimesheetTotalHours,
    checkOverlapWarning,
    checkTimesheetTotalHours,
    handleAllowEdit,
    handleClosePopupConfirm,
    handleSaveCheckAndPopup,
    handleSubmitCheckAndPopup,
    handlePopupOverLapLevel,
    checkOverlapWarningLevel
  }
}