export interface AuthState {
    isAuthenticated: boolean,
    currentUser: CurrentUserModel,
    allFieldIsValid: boolean,
}
  
export interface CurrentUserModel {
    userId?:         string;
    firstName?:      string;
    lastName?:       string;
    email?:          string;
    userType?:       string;
    taskType?:       number;
    approvalGroups?: any[];
    approvalUsers?:  any[];
    scopes?:         string[];
    userStatus?:     string;
    hasProfile?:     boolean;
    defaultStartTime?: string;
    payLocation?: string;
    division?: string|any[];
}
  

export const SET_USER = 'SET_USER'
export const SET_IS_VALID = 'SET_IS_VALID'
