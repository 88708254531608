<template>
  <div class="eye-login"
    v-on:click="showHidePwd"
  >
    <icon-svg
      width="16"
      height="10"
      :name="iconName"
      /> 
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import IconSvg from '@/layouts/IconBase.vue';

export default defineComponent({
  name: "IconShowHidePwd",
  props: {
    label: String,
    required: Boolean,
  },
  components: {
    IconSvg,
  },
  setup(props) {
    const isShow = ref<boolean>();
    const iconName = ref('eye-login');

    const showHidePwd = () => {
      if(isShow.value){
        iconName.value = "eye-login"
      } else {
        iconName.value = "eye-login-hide"
      }
      isShow.value = !isShow.value;
    };

    return {
      isShow,
      iconName,
      showHidePwd,
    };
  }
});
</script>

<style lang="scss" >
  .dark {
    .eye-login path {
      fill: #FFFFFF;
    }
  }
</style>