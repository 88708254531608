import Service from "core/service";
import type { AxiosInstance, AxiosRequestConfig, Method, AxiosError } from 'axios'

export class ManualService extends Service {

  insert(formData: any, options: any): Promise<any> {
    try {
      return this.postFormData("/Manual", formData, options);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  update(body: any, id: any): Promise<any> {
    try {
      return this.patch(`/Manual/${id}`, body);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  select(selectProps: string, filter: string | null, active?:number): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
        "$filter": active == -1 ? '' : active == 1|| active == undefined ? "(Active eq true)" : "(Active eq false)"
      };
      if (filter) {
        params["$filter"] += params["$filter"].length > 0 ? ` and (${filter})` : `${filter}`;
      }
      return this.get("/Manual", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  selectById(selectProps: string, id: number | null): Promise<any> {
    try {
     
      return this.get(`/Manual/${id}`);
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  deleteManual(params: any): Promise<any> {
    try {
      return this.delete(`/Manual/${params.ManualId}`);

    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }

  downloadFileById(id: number | null): Promise<any> {
    try {
      const options = {responseType: 'blob',};
      return this.downloadFile(`/Manual/downloadFile/${id}`, null,options);
      
    } catch (error) {
      throw (error as AxiosError).response;
    }
   
  }
}
