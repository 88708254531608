import { RootState } from "store";
import { ActionTree } from "vuex";
import { ApprovalGroupService } from "../service";
import { ApprovalGroupState } from "./types";
const service = new ApprovalGroupService();

export const actions: ActionTree<ApprovalGroupState, RootState> = {
  async insert({ commit }, params: any, options?: any) {
    if (params) {
      const data = await service.insert(params, options);
      return data;
    }
  },

  async update({ commit }, params: any) {
    if (params) {
      const _params = {
        ...params,
      }
      delete _params.Users
      delete _params['Users@odata.count']
      const data = await service.update(_params, params.ApprovalGroupId);
      return data;
    }
  },

  async select({ commit }, params: any) {
    let filterStr = [];
    if (params.filter && params.filter.Search !== undefined) {
      filterStr.push(
        `(contains(Name,'${params.filter.Search}') or contains(cast(ApprovalGroupId, 'Edm.String'),'${params.filter.Search}'))`
      );
    }
    if (params.filter && params.filter.Status !== undefined) {
      filterStr.push(`(Status eq ${params.filter.Status})`);
    }
    if (params.filter && params.filter.ApprovalGroupId !== undefined) {
      filterStr.push(`(ApprovalGroupId eq ${params.filter.ApprovalGroupId})`);
    }
    const expand =
      "ApprovalGroupMembers($expand=Approver), Users($count=true;$top=0)";

      const selectProps = "ApprovalGroupId, Name, Status, CreatedOn, UpdatedOn, GPBatchPrefix, GPBatchNotificationTo, GPBatchNotificationCC";

    const data = await service.select(
      params.currentPage || 1,
      params.pageSize || 20,
      selectProps,
      expand,
      filterStr.join(" and "),
      params.sort
    );
    commit("SET_LIST_APPROVAL", data.value);
    commit("SET_COUNT_APPROVAL", data["@odata.count"]);
    return data.value;
  },

  async selectSubmitter({ commit }, params: any) {
    let filterStr = [];
    if (params.filter && params.filter.ApprovalGroupId !== undefined) {
      filterStr.push(`(ApprovalGroupId eq ${params.filter.ApprovalGroupId})`);
    }
    const expand =
      "ApprovalGroupMembers($expand=ApprovalGroup)";
    const data = await service.selectSubmitter(
      params.currentPage || 1,
      params.pageSize || 20,
      filterStr.join(" and "),
      params.sort,
      expand
    );

    return data;
  },

  async userList({ commit }, params: any) {
    let selectProps = "";
    let expand = "";
    let filter = `UserType eq 1 and UserStatus eq 1 or UserType eq 3 and UserStatus eq 1`;
    if (params.query) {
      filter = `UserType eq 1 and UserStatus eq 1 and (contains(tolower(FirstName), '${params.query.toLowerCase()}') or contains(tolower(LastName), '${params.query.toLowerCase()}') or contains(tolower(Email),'${params.query.toLowerCase()}')) or UserType eq 3 and UserStatus eq 1 and (contains(tolower(FirstName), '${params.query.toLowerCase()}') or contains(tolower(LastName), '${params.query.toLowerCase()}') or contains(tolower(Email),'${params.query.toLowerCase()}'))`;
    }
    if (params.userIds && params.userIds.length > 0) {
      if (params.query) {
        filter = `UserType eq 1 and UserStatus eq 1 and (contains(tolower(FirstName), '${params?.query?.toLowerCase()}') or contains(tolower(LastName), '${params?.query?.toLowerCase()}') or contains(tolower(Email),'${params?.query?.toLowerCase()}')) and not(UserId in (${params?.userIds?.join(",")})) or UserType eq 3 and UserStatus eq 1 and (contains(tolower(FirstName), '${params?.query?.toLowerCase()}') or contains(tolower(LastName), '${params?.query?.toLowerCase()}') or contains(tolower(Email),'${params?.query?.toLowerCase()}')) and not(UserId in (${params?.userIds?.join(",")}))` ;
      } else {
        filter = `UserType eq 1 and UserStatus eq 1 and not(UserId in (${params.userIds.join(",")})) or UserType eq 3 and UserStatus eq 1 and not(UserId in (${params.userIds.join(",")}))` ;
      }
    }
    const data = await service.userList(selectProps, filter, params.limit);
    return data.value.map((item: any) => {
      return {
        ...item,
        FullName: `${item.FirstName} ${item.LastName ? item.LastName : ""}`,
      };
    });
  },

  async deleteApprovalGroup({ commit }, params: any) {
    if (params) {
      const data = await service.deleteApprovalGroup(params);
      return data;
    }
  },
};
