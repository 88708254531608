import { GetterTree } from 'vuex'
import { RootState } from 'store'
import { UserState } from './types'

export const getters: GetterTree<UserState, RootState> = {
    userList: (state: UserState) => state.userList,
    userCount: (state: UserState) => state.userCount,
    userParam: (state: UserState) => state.userParam,
    defaultStartTime: (state: UserState) => state.defaultStartTime,
    loading: (state: UserState) => state.loading,
    employeeList: (state: UserState) => state.employeeList,
    loadingEmployee: (state: UserState) => state.loadingEmployee,
    employeeCount: (state: UserState) => state.employeeCount,
    loadingSendMail: (state: UserState) => state.loadingSendMail,
    sendMailResult: (state: UserState) => state.sendMailResult,
    userLogs: (state: UserState) => state.userLogs,
    userLogsCount: (state: UserState) => state.userLogsCount,
    loadingUserLogs: (state: UserState) => state.loadingUserLogs
}
