export interface TimesheetApproveState {
    timeSheetsApprove?: TimeSheetHeader[],
    totalRowFetchTimeSheet: number,
    filters?: FilterTimeSheet,
    loading: boolean,
    totalHour: number,
    sortParams: string,
}

export interface FilterTimeSheet {
    fromDay?: string,
    toDay?: string,
    status?: number,
    approveBy?: string,
    search?: string,
    searchById?: string,
    searchSubmitter?: string,
    searchReviewer?: string,
    timeSheetId?: string;
    divisionJob?: string,
    searchApprovalGroup?: string,
    ownTimesheet?: number;
}

export const SET_TIME_SHEETS_APPROVE = 'SET_TIME_SHEETS_APPROVE'
export const SET_TIME_SHEETS_FILTER = 'SET_TIME_SHEETS_FILTER'
export const SET_LOADING_TIME_SHEETS = 'SET_LOADING_TIME_SHEETS'
export const SET_TOTAL_HOUR_TIME_SHEET = 'SET_TOTAL_HOUR_TIME_SHEET'
export const SET_SORT_PARAMS_TIME_SHEET = 'SET_SORT_PARAMS_TIME_SHEET'
