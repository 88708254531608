<template>
  <div class="common-layout" v-loading.fullscreen.lock="loading">
    <el-container>
      <el-header class="h-[58px]" style="padding: 0 24px !important;">
        <BaseHeader />
      </el-header>
      
      <el-container>
        <el-aside
          :class="!isCollapse ? 'nav-custom-1 isCollapse' : 'nav-custom-1'">
          <BaseSide @handleCollapse="handleCollapse" :isCollapse="isCollapse"/>
        </el-aside>
        <el-main 
          :class="$route.name == 'DashboardSuper' ? 'main-content overscroll-contain' : 'main-content overscroll-contain'" @scroll="load">
            <router-view v-slot="{ Component }">
              <component :is="Component" />
            </router-view>
            <UserForm title="Create Account"/>
            <GroupForm/>
            <AddNewTask/>
          </el-main>
       
      </el-container>
    </el-container>
  </div>
</template>

<script lang='ts'> 
import { computed, defineComponent, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import BaseHeader from '@/layouts/components/BaseHeader.vue'
import BaseSide from '@/layouts/components/BaseSide.vue'
import { useRouter, useRoute } from 'vue-router'
import UserForm from "@/components/UserForm.vue";
import GroupForm from "@/components/GroupForm.vue";
import AddNewTask from "@/components/AddNewTask.vue";
export default defineComponent({
    name: 'DefaultLayout',
    components: {
        BaseHeader,
        BaseSide,
        UserForm,
        AddNewTask,
        GroupForm,
    },
    setup() {
      const store = useStore()
      const router = useRouter();
      const loading = computed(() => store.state.loading)
      const isOpen = computed(() => store.state.isOpen)
      const isCollapse = ref(true);
      const widthOfBody = ref<number>(1920);

      const onResize = () => {
        if (window?.innerWidth <= 1440) {
          isCollapse.value = false
        } else {
          isCollapse.value = true
        }
      }

      const select = () => {
        store.dispatch("setting/getSettings")
      }

      onBeforeMount(() => {
        widthOfBody.value = window.innerWidth;
        window.addEventListener("resize", onResize);
        select()
      })

      const load = (e:any) => {
        if ('Dashboard' == router.currentRoute.value.name) {
          const clientHeight = e.target.clientHeight
          const scrollHeight = e.target.scrollHeight
          const scrollTop = e.target.scrollTop
        
          if ((scrollTop + clientHeight + 50) >= scrollHeight) {
          }
        }
        return;
      }

      const handleCollapse = () => {
        isCollapse.value = !isCollapse.value
        store.commit('TOGGLE_SIDEBAR')
      }

      return {
          loading,
          isOpen,
          load,
          isCollapse,
          handleCollapse
      }
    },
})
</script>
    