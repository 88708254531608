export const standardizeTime = (option: any) => {
  const data = {
    startTime: 0,
    endTime: 0,
  };

  if (!option.startTime?.isInit && !option.endTime?.isInit) {
    let startTimeHours = option?.startTime?.hours ?? 0;
    let endTimeHours = option?.endTime?.hours ?? 0;

    if (option?.startTime?.isAm) {
      if (option?.startTime?.hours == 12) {
        startTimeHours = 0;
      } else {
        startTimeHours = option?.startTime?.hours ?? 0;
      }
    }
    if (option?.endTime?.isAm) {
      if (option?.endTime?.hours == 12) {
        endTimeHours = 0;
      } else {
        endTimeHours = option?.endTime?.hours ?? 0;
      }
    }

    const startTimeMinutes = option?.startTime?.minutes ?? 0;
    const endTimeMinutes = option?.endTime?.minutes ?? 0;

    const totalStartTimeMinutes = startTimeMinutes + startTimeHours * 60;
    const totalEndTimeMinutes = endTimeMinutes + endTimeHours * 60;

    data.startTime = totalStartTimeMinutes;
    data.endTime = totalEndTimeMinutes;
  } else {
    if (option?.startTime?.isAm && option?.startTime?.hours == 12) {
      if (option?.startTime?.minutes == 0) {
        data.startTime = 0;
      } else {
        data.startTime =
          option?.startTime?.hours * 60 + option?.startTime?.minutes - 720;
      }
    }
  }

  return data;
};

export const checkTimeInit = (timeSheetHeader: TimeSheetHeader) => {
  const result = timeSheetHeader.TimesheetLines?.map((timesheet) => {
    if (timesheet?.Option?.endTime?.isInit) {
      timesheet.EndTime = undefined;
    }
    if (timesheet?.Option?.startTime?.isInit) {
      timesheet.StartTime = undefined;
    }
    return timesheet;
  });

  return result;
};
