import { AxiosError } from "axios";
import Service from "core/service";

export class SettingsService extends Service {
  getSettings(): Promise<any> {
    try {
      return this.get("/Setting");
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }
 
  patchSettings(params: any): Promise<any> {
    try {
      return this.patch("/Setting", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  dropdownPayLocation(
    selectProps: string,
    req: any | null
  ): Promise<any> {
    try {
      let params: any = {
        $select: selectProps,
      };
      if (req) {
        if (req.filter) {
          params["$filter"] = req.filter;
        }

        if (req.orderBy) {
          params["$orderby"] = req.orderBy;
        }

        params["$count"] = req.count ? req.count : true;
        params["$top"] = req.pageSize ? req.pageSize : 100;
        params["$skip"] =
          req.pageSize * (req.currentPage ? req.currentPage - 1 : 0);
      } else {
        params["$count"] = true;
        params["$top"] = 1;
        params["$skip"] = 0;
      }

      return this.get("/PayLocation", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  patchPayLocation(params: any): Promise<any> {
    try {
      return this.post("/PayLocation", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  updatePayLocation(params: any, id: string): Promise<any> {
    try {
      return this.patch(`/PayLocation/${id}`, params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  deletePayLocation(params: any): Promise<any> {
    try {
      return this.post("/PayLocation/DeleteMany", params);
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }

  resetSetting(): Promise<any> {
    try {
      return this.put("/Setting/Reset");
    } catch (error) {
      throw (error as AxiosError).response;
    }
  }
}
