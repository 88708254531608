import { MutationTree } from "vuex";
import { SettingState } from "./types";

export const mutations: MutationTree<SettingState> = {
    SET_PAYLOCATION_COUNT: (state: SettingState, itemCount: number) => {
        state.paylocationCount = itemCount;
    },
    SET_SETTINGS: (state: SettingState, data: any) => {
        state.settings = data;
    },
};