import { RootState } from "store";
import { ActionTree } from "vuex";
import { TimeSheetApproveService } from "../service";
import { TimesheetApproveState, SET_TIME_SHEETS_APPROVE, FilterTimeSheet, SET_TOTAL_HOUR_TIME_SHEET, SET_SORT_PARAMS_TIME_SHEET } from "./types";
import { timeSheetStatusName } from '../../../dashboard/store/types'
import _ from 'lodash'
import dayjs from "dayjs";
import { convertTaskIdToType, formatTime } from "../../../../utils";
import { ProfileService } from '../../../profile/service'

const service = new TimeSheetApproveService();
const profileService =new ProfileService()
export const actions: ActionTree<TimesheetApproveState, RootState> = {

    async actLoadTimeSheet({ commit }, params: any) {
        const filterTimeSheet:FilterTimeSheet = this.getters['timeSheetsApprove/filters']
        let expand = `TimesheetLines($expand=Job,JobCostCode,GLAccount,WorkOrderTask, WorkOrder,TimesheetAdditionals($select=Manufacturer)),User($expand=ApprovalGroup),LastActorUser($select=Email,FirstName,LastName),
        ApprovalGroup($select=Name,ApprovalGroupId)`
        const filters = []
        const currentUser = this.getters['auth/currentUser']
        if (filterTimeSheet){
            if (filterTimeSheet.fromDay && filterTimeSheet.toDay){
                filters.push(`StartDate ge ${filterTimeSheet.fromDay} and StartDate le ${filterTimeSheet.toDay}`)
            }
            if ((filterTimeSheet.status || filterTimeSheet.status === 0) && filterTimeSheet.status != -1) {
                filters.push(`TimesheetStatus eq ${filterTimeSheet.status}`)
            }
            if (filterTimeSheet.approveBy) {
                filters.push(`ApproverId eq ${filterTimeSheet.approveBy}`)
            }
            if (filterTimeSheet.search) {
                const isNumber = !isNaN(parseInt(filterTimeSheet.search))
                if (isNumber) {
                  filters.push(`startswith(cast(TimesheetHeaderId, 'Edm.String'),'${filterTimeSheet.search.toString()}')`)
                }else{
                    filters.push(`(contains(tolower(User/FirstName),'${filterTimeSheet.search.toLowerCase()}') or contains(tolower(User/LastName),'${filterTimeSheet.search.toLowerCase()}'))`)
                }
            }

            if (filterTimeSheet.searchById) {
                filters.push(`startswith(cast(TimesheetHeaderId, 'Edm.String'),'${filterTimeSheet.searchById.toString()}')`)
            }

            if(filterTimeSheet.searchSubmitter) {
                filters.push(`(contains(tolower(User/Email), '${filterTimeSheet.searchSubmitter}') or contains(tolower(concat(concat(User/FirstName,' '), User/LastName)), '${filterTimeSheet.searchSubmitter}') )`);
            }

            if(filterTimeSheet.searchReviewer) {
                filters.push(`(contains(tolower(Approver/Email), '${filterTimeSheet.searchReviewer}') or contains(tolower(concat(concat(Approver/FirstName,' '), Approver/LastName)), '${filterTimeSheet.searchReviewer}') )`);
            }
            if (filterTimeSheet.searchApprovalGroup) {
                const isNumber = !isNaN(parseInt(filterTimeSheet.searchApprovalGroup))
                if (isNumber) {
                    filters.push(`startswith(cast(User/ApprovalGroupId, 'Edm.String'),'${filterTimeSheet.searchApprovalGroup.toString()}')`)
                } else {
                    filters.push(`contains(tolower(User/ApprovalGroup/Name),'${filterTimeSheet.searchApprovalGroup.toLowerCase()}')`)
                }
            }
            if(filterTimeSheet.ownTimesheet) {
              filters.push(`UserId eq ${currentUser.userId}`)
            }
        }
        
        const result = await service.fetchTimeSheet(params.currentPage, params.pageSize, expand, filters.join(' and '), params.orderby)
        const data  = result.value.map((item: TimeSheetHeader)=>{
            const timeSheet: TimeSheetHeader = {
                ...item
            }
            if (timeSheet.StartDate) {
                timeSheet.StartDate = dayjs(timeSheet.StartDate).format('MMM DD, YYYY')
            }
            timeSheet.TimesheetLines = []
            if (timeSheet.TimesheetStatus || timeSheet.TimesheetStatus === 0) {
                timeSheet.TimesheetStatusName = timeSheetStatusName[timeSheet.TimesheetStatus as keyof typeof timeSheetStatusName]
            }
            if (item.User) {
                item.User.FullName = `${item.User.LastName ?? ''} ${item.User.FirstName ?? ''}`
            }
            if (item.Approver) {
                item.Approver.FullName = `${item.Approver.LastName ?? ''} ${item.Approver.FirstName ?? ''}`
            }
            
            if (item.TimesheetLines && item.TimesheetLines.length > 0){
                item.TimesheetLines.forEach((line)=> {
                    
                    const timesheetLine: TimeSheetLine = {
                        ...line
                    }
                    timesheetLine.JobTitle = '---'

                    if (line.TimesheetLineType === 0){
                        timesheetLine.JobType = 'Rental'
                    } else if(line.TimesheetLineType === 1){
                        timesheetLine.JobType = 'Unbill'
                    } else if(line.TimesheetLineType === 2){
                        timesheetLine.JobType = 'Job cost'
                    }

                    if (line.WorkOrderTaskId && line.WorkOrderTask) {
                        timesheetLine.JobTitle = `${line.WorkOrderTask.Name} ${line.WorkOrderTask.Description}`
                    }
                    if (line.JobCostCodeId && line.JobCostCode) {
                        timesheetLine.JobTitle = `${line.JobCostCode.Name} ${line.JobCostCode.Description}`
                    }
                    if (line.Glaccount && line.GlaccountId) {
                        timesheetLine.JobTitle = `${line.Glaccount.Description}`
                    }
                    if (line.TimesheetLineMinutes && line.TimesheetLineMinutes > 0) {
                        timesheetLine.TimesheetLineMinutesTitle = `${Math.floor(line.TimesheetLineMinutes/60)}h ${line.TimesheetLineMinutes%60}min`
                    }
                    timeSheet.TimesheetLines?.push(timesheetLine)
                })
            }
            if (timeSheet.TotalDuration > 0){
                timeSheet.TotalWorkingTitle = `${Math.floor(timeSheet.TotalDuration/60)}h ${timeSheet.TotalDuration%60}min`
                timeSheet.TotalWorkingHours = timeSheet.TotalDuration/60
            }else {
                timeSheet.TotalWorkingTitle = '0h'
                timeSheet.TotalWorkingHours = 0
            }
            return timeSheet;
        })
        commit(SET_TIME_SHEETS_APPROVE, {lstTimeSheets: data, count: result['@odata.count']})
        commit(SET_SORT_PARAMS_TIME_SHEET, params?.orderby || "")
    },

    async actGetTimeSheet({ commit }, params: any){
        const expand = `TimesheetLines($expand=JobCostCode,GLAccount,WorkOrderTask,
            TimesheetAdditionals(select=TimesheetAdditionalId,Manufacturer,Model,Smureading,SerialNumber,CustomerName,CustomerEmail,Locations,TravelKms,
                Rego,WorkerSignature,CustomerSignature,AdditionalInfo,CustomerAcceptanceName)),Approver`
        const result: TimeSheetHeader = await service.fetchTimeSheetById(params.id, expand)
        const lstTimeSheetLineRental:any = []
        const lstTimeSheetLineJob:any = []
        const lstTimeSheetLineUnbill:any = []
        if (result) {
            if (result.TimesheetLines){
                for (let index = 0; index <  result.TimesheetLines.length; index++) {
                    const line = result.TimesheetLines[index]
                   
                    line.JobTitle = ''
                    if (line.TimesheetLineMinutes && line.TimesheetLineMinutes > 0) {
                        line.TimesheetLineMinutesTitle = `${Math.floor(line.TimesheetLineMinutes/60)}h ${line.TimesheetLineMinutes%60}min`
                    }else {
                        line.TimesheetLineMinutesTitle = '0h'
                    }
                    if (line.WorkOrderTaskId && line.WorkOrderTask) {
                        line.JobTitle = `${line.WorkOrderTask.Name} ${line.WorkOrderTask.Description}`
                        line.JobType = 'Rental'
                        const expandWorkOrder = 'WorkOrder($expand=Equipment)'
                        const workOder = await profileService.getWorkOrderTaskById(line.WorkOrderTaskId, expandWorkOrder)
                        line.WorkOrderTask = { ...workOder }
                        lstTimeSheetLineRental.push({...line})
                        
                    }
                    if (line.JobCostCodeId && line.JobCostCode) {
                        line.JobTitle = `${line.JobCostCode.Name} ${line.JobCostCode.Description}`
                        line.JobType = 'Job cost'
                        lstTimeSheetLineJob.push({...line})
                    }
                    if (line.Glaccount && line.GlaccountId) {
                        line.JobTitle = `${line.Glaccount.Description}`
                        line.JobType = 'Unbill'
                        lstTimeSheetLineUnbill.push({...line})
                    }
                }
            }
            if (result.StartDate) {
                result.StartDate = dayjs(result.StartDate).format('MMM DD, YYYY')
            }
            if (result.StartTime) {
                result.StartTimeObjects = formatTime(result.StartTime)
            }
            if (result.TimesheetStatus || result.TimesheetStatus === 0) {
                result.TimesheetStatusName = timeSheetStatusName[result.TimesheetStatus as keyof typeof timeSheetStatusName]
            }
            if (result.TotalDuration > 0){
                result.TotalWorkingTitle = `${Math.floor(result.TotalDuration/60)}h ${result.TotalDuration%60}min`
                result.TotalWorkingHours = result.TotalDuration/60
            }else {
                result.TotalWorkingTitle = '0h'
                result.TotalWorkingHours = result.TotalDuration/60
            }
            result.StartDate
        }
        return {
            timeSheetHeader: result,
            lstTimeSheetLineRental,
            lstTimeSheetLineJob,
            lstTimeSheetLineUnbill
        }
    },

    async actApproveTimeSheet({ commit }, params: any){
        const dataApprove = {
            Id: params.id,
            TimesheetStatus: 2
        }
        const result = await service.updateTimeSheet(dataApprove)
        return result
    },

    async actApproveTimeSheets({ commit }, params: any){
        const result = await service.approveTimeSheets( params.ids )
        return result
    },
    
    async actCancelTimeSheet({ commit }, params: any){
        const dataApprove = {
            Id: params.id,
            TimesheetStatus: 5
        }
        const result = await service.updateTimeSheet(dataApprove)
        return result
    },

    async actDeclineTimeSheet({ commit }, params: any){
        const dataApprove = {
            Id: params.id,
            TimesheetStatus: 3,
            Reason: params.reason
        }
        const result = await service.updateTimeSheet(dataApprove)
        return result
    },

    async actReassignApprovalGroup({ commit }, params: any) {
      const result = await service.updateApprovalGroup(params.TimesheetHeaderId, params.ApprovalGroupId)
      return result
    },

    async actFetchUserApprove({commit}, params:any){
        let filter = 'UserType ne 0'
        const result = await service.fetchUserApprove(filter)
        return result.value;
    },
    
    async loadCountSuperTotalTimesheet({ commit }, params: any) {
        let filterStr = ''
        let totalHour = 0
        const currentUser:any = this.getters['auth/currentUser']
        const filterTimeSheet = dayjs().format("YYYY-MM-DD");
        filterStr = (`StartDate ge ${filterTimeSheet} and StartDate le ${filterTimeSheet} and TimesheetStatus eq 1 or StartDate ge ${filterTimeSheet} and StartDate le ${filterTimeSheet} and TimesheetStatus eq 2`);
        const response = await service.fetchTimeSheet(1, 100, '', filterStr, params?.orderby)
        const result = response.value.filter((item: any) => item.UserId == currentUser.userId)
        if (result.length > 0) {
            totalHour = result.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.TotalDuration, 0);
        }
        commit(SET_TOTAL_HOUR_TIME_SHEET, totalHour)
    },
};
