<template>
  <el-row class="h-screen">
    <router-view>
    </router-view>
    <!-- <div class="login-form">
      <div>
        <div class="logo-item">
          <icon-svg width="254" height="76" name="logo" />
        </div>
        <div style="max-width: 360px; margin: 0 auto;">
          <router-view>
          </router-view>
        </div>
      </div>

    </div>
    <div class="bg hidden-sm-and-down"><img class="img" src="@/assets/bg1.png" />
    </div> -->
  </el-row>
</template>
<script lang="ts">
import 'element-plus/theme-chalk/display.css'
import { defineComponent } from "vue";
import IconSvg from '@/layouts/IconBase.vue';
export default defineComponent({
  name: "AuthLayout",
  components: {
    IconSvg
  }

});
</script>
<style lang="scss" scoped>
.login-form {
  width: 37.5%;
}

.logo-item {
  margin: 23.33% auto 9.26% auto;
  max-width: 360px;
}

.bg {
  width: 62.5%;
}

.img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .login-form {
    width: 100%;
  }
}
</style>
