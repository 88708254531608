<template>
      <el-dialog
      v-if="type == 'Approving'"
      v-model="showDialog"
      :width="width"
      align-center
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="grid justify-items-center pt-4">
        <div class="mb-[24px]">
          <icon-svg :name="iconPopup" />
        </div>
        <div class="text-xl font-medium mb-[16px]">{{ type }}</div>
        <p class="text-base font-bold" style="text-align: center; white-space: pre-line; padding: 0 10px;">
          {{ message }}
        </p>
      </div>
      <template #footer>
        <div class="flex justify-center mt-4 mb-4 ...">
          <el-button class="justify-self-center btn-cancel w-[83px] ml-[16px]" @click="showDialog = false" v-if="lblBtnCancel">{{ lblBtnCancel }}</el-button>
          <el-button class="justify-self-center btn-cancel w-[83px] ml-[16px]" @click="showDialog = false" v-else>{{lblBtnSubmit?'No':'Ok'}}</el-button>
          <el-button v-if="lblBtnSubmit" class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px]" @click="handleSubmit">
            <el-icon>
              <icon-svg  width="10" height="10" :name="iconBtnName" />
            </el-icon>
            <span class="ml-[6px] font-bold">{{ lblBtnSubmit }}</span>
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-if="type !== 'Decline' && type !== 'Approving'"
      v-model="showDialog"
      :width="width"
      align-center
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="grid justify-items-center pt-4">
        <div class="mb-[24px]">
          <icon-svg :name="iconPopup" />
        </div>
        <div class="text-xl font-medium mb-[16px]">{{ type }}</div>
        <p class="text-base font-bold" style="text-align: center; white-space: pre-line; padding: 0 10px;">
          {{ message }}
        </p>
      </div>
      <template #footer>
        <div class="flex justify-center mt-4 mb-4 ...">
          <el-button class="justify-self-center btn-cancel w-[83px] ml-[16px]" @click="showDialog = false" v-if="lblBtnCancel">{{ lblBtnCancel }}</el-button>
          <el-button class="justify-self-center btn-cancel w-[83px] ml-[16px]" @click="showDialog = false" v-else>{{lblBtnSubmit?'No':'Ok'}}</el-button>
          <el-button v-if="lblBtnSubmit" class="justify-self-center delete mr-0 btn-confirm pl-[32px] ml-[16px] pr-[32px]" @click="handleSubmit">
            <el-icon>
              <icon-svg  width="10" height="10" :name="iconBtnName" />
            </el-icon>
            <span class="ml-[6px] font-bold">{{ lblBtnSubmit }}</span>
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-if="type == 'Decline'"
      v-model="showDialog"
      width="440px"
      align-center
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="grid justify-items-center px-[20px]">
        <div class="text-base" style="font-weight: 600;">
          {{ message }}
        </div>
        <div class="mt-[16px] w-full">
          <el-input
            v-model="reason"
            :rows="5"
            type="textarea"
            placeholder="Reason for decline *"
            maxlength="1000"
          />
          <span v-if="errorReason" class="text-[#f56c6c]">reason for decline</span>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-end mt-4 ...">
          <el-button class="justify-self-center btn-cancel w-[83px] ml-[16px]" @click="handleCloseReason">No</el-button>
          <el-button class="justify-self-center delete mr-0 btn-confirm pl-[32px] pr-[32px]" @click="handleDecline">
            <el-icon v-if="iconBtnName">
              <icon-svg  width="10" height="10" :name="iconBtnName" />
            </el-icon>
            <span class="ml-[6px] font-bold">{{ lblBtnSubmit }}</span>
          </el-button>
        </div>
      </template>
    </el-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import IconSvg from '@/layouts/IconBase.vue';
export default defineComponent({
name: "IconCalendar",
props: {
    type: String,
    message: String,
    lblBtnSubmit: String,
    lblBtnCancel: String,
    iconBtnName: String,
    iconPopup: String,
    width: Number,
},
components: {
    IconSvg,
},
setup(props, ctx) {
    const type = ref<string>(props.type!)
    const message = ref<string>(props.message!)
    const lblBtnSubmit = ref<string>(props.lblBtnSubmit!)
    const lblBtnCancel = ref<string>(props.lblBtnCancel!)
    const iconBtnName = ref<string>(props.iconBtnName!)
    const iconPopup = ref<string>(props.iconPopup!)
    const showDialog = ref<boolean>(false);
    const reason = ref<string>('');
    const errorReason = ref<boolean>(false); 
    const width = ref<number>(props.width == undefined ? 352 : props.width)
    const handleSubmit = () => {
        ctx.emit('submit')
    }

    const handleDecline = () => {
      if (reason.value === '') {
        errorReason.value = true
      } else {
        ctx.emit('submit', { reason: reason.value })
      }
    }

    const handleCloseReason = () => {
      showDialog.value = false
      reason.value = ''
    }
    
    watch(showDialog, () => {
      if (!showDialog.value) {
        errorReason.value = false
      }
    })
   
    return {
        type,
        showDialog,
        handleSubmit,
        reason,
        handleDecline,
        message,
        lblBtnSubmit,
        lblBtnCancel,
        iconBtnName,
        iconPopup,
        width,
        errorReason,
        handleCloseReason
    }
}
});
</script>
  
<style lang="scss" >

</style>