<template>
  <div>
    <StartTimeSheet
      :errorMessage="errors?.overlap?.id === index ? errors?.overlap?.message : null" :totalHour="totalHour"
      :disable="!allowUpdate" :hours="taskInfo.startTime.hours" :minutes="taskInfo.startTime.mins" :taskId="taskId" @submit="handleInputTimeSheet" ref="refTimeSheet"/>
  </div>
  <el-form
    ref="formRef"
    :validate-on-rule-change="false"
    :model="taskInfo"
    :rules="rules"
    label-width="132px"
    size="default"
    status-icon
    require-asterisk-position="right"
    label-position="left"
  >
    <el-form-item label="GL Entry" prop="glEntry">
      <el-select :placeholder="isEdit ? ' ' : 'Select GL Entry'" v-model="taskInfo.glEntry" class="w-[400px]" size="large" popper-class="select-custom-popper w-[390px]" :disabled="!allowUpdate" clearable>
        <el-option
          v-for="item in dropDownGlEntry"
          :key="item.GlaccountId"
          :label="item.Description"
          :value="item.GlaccountId ? item.GlaccountId: 0"
          
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Job Description" prop="jobDescription">
      <el-input
        v-model="taskInfo.jobDescription"
        :rows="2"
        type="textarea"
        :placeholder="isEdit ? ' ': 'Enter Job Description'"
        size="large"
        class="w-[400px]"
        :disabled="!allowUpdate"
        maxlength="1000"
      />
    </el-form-item>
    <div v-if="allowInputFiledService">
      <!-- <el-form-item label="Field Service" prop="additionInfo">
      <div class="flex justify-end w-full">
        <el-switch v-model="taskInfo.additionInfo" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-switch>
      </div>
    </el-form-item> -->
    <el-form-item
      label="Client Name"
      prop="clientName"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.clientName" size="large" placeholder="Enter Client Name" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Location"
      prop="location"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.location" size="large" placeholder="Enter Location" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Manufacturer"
      prop="manufacturer"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.manufacturer" placeholder="Enter Manufacturer" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Model"
      prop="model"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.model" placeholder="Enter Model" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Veh. Reg. No"
      prop="vehRegNo"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.vehRegNo" placeholder="Enter Veh. Reg. No" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Serial No"
      prop="serialNo"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.serialNo" placeholder="Enter Serial No" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="SMU Hours"
      prop="SMUHour"
      v-if="taskInfo.additionInfo === true"
    >
      <img
        src="../assets/icon_clock.svg"
        height="16"
        width="16"
        class="el-icon-clock"
      />
      <el-input-number v-model="taskInfo.SMUHour" :min="0" :controls="false" size="large" class="w-[150px]" :disabled="timeSheetStatus != 0 || allowUpdate == false"/>
    </el-form-item>
    <el-form-item
      label="Travel KMs"
      prop="travelKMS"
      v-if="taskInfo.additionInfo === true"
    >
      <img
        src="../assets/icon_clock.svg"
        height="16"
        width="16"
        class="el-icon-clock"
      />
      <el-input-number v-model="taskInfo.travelKMS" :min="0" :controls="false" size="large" class="w-[150px]" :disabled="timeSheetStatus != 0 || allowUpdate == false"/>
    </el-form-item>
    <el-form-item
      label="Field Service Notes"
      prop="fieldServiceNote"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input
        v-model="taskInfo.fieldServiceNote"
        :rows="3"
        type="textarea"
        placeholder="Enter Notes"
        size="large"
        :disabled="timeSheetStatus != 0 || allowUpdate == false"
      />
    </el-form-item>
    <el-form-item
      label="Customer Email"
      prop="customerEmail"
      v-if="taskInfo.additionInfo === true"
    >
      <el-input v-model="taskInfo.customerEmail" placeholder="Enter Customer Email" size="large" :disabled="timeSheetStatus != 0 || allowUpdate == false"></el-input>
    </el-form-item>
    <el-form-item
      label="Customer Acceptance Name"
      prop="customerAcceptanceName"
      v-if="taskInfo.additionInfo === true"
      class="customer-acceptance-name"
    >
      <el-input
        v-model="taskInfo.customerAcceptanceName"
        size="large"
        placeholder="Enter Customer Acceptance Name"
        :disabled="timeSheetStatus != 0 || allowUpdate == false"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="Customer Acceptance"
      prop="isCustomerAcceptance"
      v-if="taskInfo.additionInfo === true"
    >
      <Vue3Signature @mousedown="(e: any) => handleSignatureClick(e, 'customer')" class="sign-pad" ref="signatureCustomer" :sigOption="sigOption" :h="'200px'" :w="'400px'" :disabled="timeSheetStatus != 0 || allowUpdate == false"></Vue3Signature>
      <div class="w-full flex justify-end mt-[8px]" v-if="timeSheetStatus == 0 && allowUpdate">
          <el-button class="btn-cancel" @click="() => handleClearSignature('customer')">Clear</el-button>
        </div>
    </el-form-item>
    <el-form-item
      label="Mechanical Acceptance"
      prop="isMechanicalAcceptance"
      v-if="taskInfo.additionInfo === true"
    >
     <Vue3Signature @mousedown="(e: any) => handleSignatureClick(e, 'mechanical')" class="sign-pad" ref="signatureMechanical" :sigOption="sigOption" :h="'200px'" :w="'400px'" :disabled="timeSheetStatus != 0 || allowUpdate == false"></Vue3Signature>
     <div class="w-full flex justify-end mt-[8px]" v-if="timeSheetStatus == 0 && allowUpdate">
          <el-button class="btn-cancel" @click="() => handleClearSignature('mechanical')">Clear</el-button>
     </div>
    </el-form-item>
    </div>
  </el-form>
</template>
  <script lang="ts">
import _ from 'lodash';
import { computed, defineComponent, onBeforeMount, reactive, ref, watch } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { useStore } from "vuex";
import { TASK_TYPES } from "core/constants";
import { Actions } from "modules/dashboard/store/types";
import { imageEndpoint } from '@/config/enviroments';
import StartTimeSheet from './StartTimeSheet.vue'
export default defineComponent({
  name: "UnbilledTask",
  props: {
    data: {
      index: Number,
      type: Object,
      taskId: String,
      gLAccountId: Number,
      additionInfo: Object,
      jobDescription: String,
      hours: Number,
      mins: Number,
      timeSheetStatus: Number,
    },
    errors: Object,
    isEdit: Boolean,
    allowUpdate: Boolean,
    taskTypeDisplay: Object,
    totalHour: Number,
  },
  components: {
    StartTimeSheet
  },
  setup(props, ctx) {
    const formRef = ref<FormInstance>();
    const isEdit = ref<any>(props.isEdit);
    const errors = ref<any>(props.errors);
    const taskId = ref<string>(props.data?.taskId ?? '')
    const refTimeSheet =  ref<InstanceType<typeof StartTimeSheet> | null>();
    const timeSheetStatus = ref<number>(props.data?.timeSheetStatus ?? 0)
    const DEFAULT_TASK_INFO: any = {
      glEntry: undefined,
      jobDescription: "",
      additionInfo: false,
      clientName: "",
      location: "",
      manufacturer: "",
      model: "",
      vehRegNo: "",
      serialNo: "",
      SMUHour: null,
      travelKMS: null,
      customerEmail: "",
      fieldServiceNote: "",
      customerAcceptance: "",
      mechanicalAcceptance: "",
      isCustomerAcceptance: "",
      isMechanicalAcceptance: "",
      startTime: {
        hours: props.data?.hours ?? 0,
        mins: props.data?.mins ?? 0,
      },
      TimesheetLineMinutes: 0,
      customerAcceptanceName: "",
    }
    const defaultValueStr = JSON.stringify(DEFAULT_TASK_INFO)
    const taskInfo = ref<UnBillTask>({ ...DEFAULT_TASK_INFO });
    const rules = computed(() =>({
      glEntry: [
        {
          required: true,
          message: "Please select GL Entry",
          trigger: ["blur", "change"],
        },
      ],
      clientName: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter client name",
          trigger: ["blur", "change"],
        },
      ],
      location: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter location",
          trigger: ["blur", "change"],
        },
      ],
      manufacturer: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter manufacturer",
          trigger: ["blur", "change"],
        },
      ],
      model: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter manufacturer",
          trigger: ["blur", "change"],
        },
      ],
      vehRegNo: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Veh. Reg. No",
          trigger: ["blur", "change"],
        },
      ],
      serialNo: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Serial no",
          trigger: ["blur", "change"],
        },
      ],
      SMUHour: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter SMU Hours",
          trigger: ["blur", "change"],
        },
      ],
      travelKMS: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter Travel KMs",
          trigger: ["blur", "change"],
        },
      ],
      fieldServiceNote: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter field service note",
          trigger: ["blur", "change"],
        },
      ],
      customerEmail: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter customer email",
          trigger: ["blur", "change"],
        },
        {
          type: "email",
          message: "Please input correct email",
          trigger: ["blur", "change"],
        },
      ],
      isCustomerAcceptance: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please sign",
          trigger: ["blur", "change"],
        }
      ],
      isMechanicalAcceptance: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please sign",
          trigger: ["blur", "change"],
        }
      ],
      customerAcceptanceName: [
        {
          required: taskInfo.value.additionInfo,
          message: "Please enter customer acceptance name",
          trigger: ["blur", "change"],
        }
      ]
    }));
    const dropDownGlEntry = ref<GLAccount[]>([])
    const store = useStore();
    const actionTimeSheet = computed(() => store.getters["dashboard/action"])
    const signatureMechanical = ref<any>(null)
    const signatureCustomer = ref<any>(null)

    const signatureCustomerTemp = ref<boolean>(false)
    const signatureMechanicalTemp = ref<boolean>(false)

    const allowUpdate = ref<boolean>(props.allowUpdate ?? true)
    const allowInputFiledService = computed(() => {
      const user = store.getters["auth/currentUser"];
      return user.showFieldService;
    });
    const sigOption = computed(() => {
      return {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      }
    });
    onBeforeMount(async () => {
      const getBase64FromUrl = async (url: string) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          }
        });
      }
      store.dispatch("profile/actGetDropDownGLAccount", {
          filter: null,
      }).then(async (data) => {
        dropDownGlEntry.value = data.sort((a: any,b: any)=> {
          const aNumber = parseInt(a.Description.split(' - ')?.[0])
          const bNumber = parseInt(b.Description.split(' - ')?.[0])
          return aNumber - bNumber
        })
        
        if (props.isEdit && props.data?.gLAccountId) {
          allowUpdate.value = props.allowUpdate
          taskInfo.value.glEntry = props.data?.gLAccountId;
          taskInfo.value.jobDescription =  props.data?.jobDescription || "";
          taskInfo.value.startTime.hours = props.data?.hours
          taskInfo.value.startTime.mins = props.data?.mins
          if (props.data?.additionInfo) {
              taskInfo.value.TimesheetAdditionalId = props.data?.additionInfo.TimesheetAdditionalId;
              taskInfo.value.additionInfo = true;
              taskInfo.value.clientName = props.data?.additionInfo.CustomerName;
              taskInfo.value.location =  props.data?.additionInfo.Locations;
              taskInfo.value.manufacturer = props.data?.additionInfo.Manufacturer;
              taskInfo.value.model = props.data?.additionInfo.Model;
              taskInfo.value.vehRegNo = props.data?.additionInfo.Rego;
              taskInfo.value.serialNo = props.data?.additionInfo.SerialNumber;
              taskInfo.value.SMUHour = props.data?.additionInfo.Smureading;
              taskInfo.value.travelKMS = props.data?.additionInfo.TravelKms;
              taskInfo.value.customerEmail= props.data?.additionInfo.CustomerEmail;
              taskInfo.value.fieldServiceNote = props.data?.additionInfo.AdditionalInfo;
              taskInfo.value.customerAcceptanceName = props.data?.additionInfo.CustomerAcceptanceName;
              taskInfo.value.mechanicalAcceptance = props.data?.additionInfo.WorkerSignature;
              taskInfo.value.customerAcceptance = props.data?.additionInfo.CustomerSignature;
              await new Promise(f => setTimeout(f, 100));
              if (props.data?.additionInfo.WorkerSignature) {
                const base64 =!!taskInfo.value.mechanicalAcceptance && taskInfo.value.mechanicalAcceptance.length > 200 ? `${taskInfo.value.mechanicalAcceptance}` : await getBase64FromUrl(`${imageEndpoint}/${taskInfo.value.mechanicalAcceptance}`)
                signatureMechanical.value.fromDataURL(base64)
                taskInfo.value.isMechanicalAcceptance = "change" ;
              }
              if (props.data?.additionInfo.CustomerSignature) {
                const base64 =!!taskInfo.value.customerAcceptance && taskInfo.value.customerAcceptance.length > 200 ? `${taskInfo.value.customerAcceptance}` : await getBase64FromUrl(`${imageEndpoint}/${taskInfo.value.customerAcceptance}`)
                signatureCustomer.value.fromDataURL(base64);
                taskInfo.value.isCustomerAcceptance = "change" ;
              }
            }
        }
      })
    });
    const validFormData = async () => {
      await formRef.value?.validate(async (valid:any, fields:any) => {
        const validTimeSheet = refTimeSheet.value!.validData()
        const validData = valid && !validTimeSheet
        const dataSubmit = {
          ... _.omit(taskInfo.value,['isCustomerAcceptance,isMechanicalAcceptance']),
            taskType: TASK_TYPES.UnBilled,
            taskId: props.data?.taskId,
            valid: validData,
        }

        if (validData) {
          // store.commit("SET_LOADING", true);
          if (signatureCustomerTemp.value) {
            const signatureCustomerPath = await uploadSinature(signatureCustomer.value?.save('image/png'))
            dataSubmit.customerAcceptance = signatureCustomerPath.image
          }

          if (signatureMechanicalTemp.value) {
            const signatureMechanicalPath = await uploadSinature( signatureMechanical.value?.save('image/png'))
            dataSubmit.mechanicalAcceptance = signatureMechanicalPath.image
          }
        }
        ctx.emit('submit', dataSubmit)
      })
    }
    const handleInputTimeSheet = (data: any) => {
      taskInfo.value.startTime.hours = data.hours
      taskInfo.value.startTime.mins = data.minutes
      taskInfo.value.TimesheetLineMinutes = (data.hours * 60) + data.minutes
      ctx.emit('updateTimeSheet',{taskId: taskId.value, hours:data.hours, mins: data.minutes })
    }
    const handleSignatureClick = (event: any, type: "customer" | "mechanical") => {
      // Xử lý sự kiện khi người dùng tương tác với vùng ký tên
      if (type == "customer") {
        signatureCustomerTemp.value = true
        taskInfo.value.isCustomerAcceptance = "change"
      } else {
        signatureMechanicalTemp.value = true
        taskInfo.value.isMechanicalAcceptance = "change"
      }
    };

    const handleClearSignature = (type: "customer" | "mechanical") => {
      if (type == "customer") {
        taskInfo.value.customerAcceptance = '';
        taskInfo.value.isCustomerAcceptance= '';
        signatureCustomer.value.clear()
        signatureCustomerTemp.value = false
      } else {
        taskInfo.value.mechanicalAcceptance = '';
        taskInfo.value.isMechanicalAcceptance='';
        signatureMechanical.value.clear()
        signatureMechanicalTemp.value = false
      }
    }

    const convertBase64ToBlob = (image: any) => new Promise((resolve, reject) => {
      return fetch(image)
        .then(res => resolve(res.blob()))
        .then(err => reject(err))
    });

    const uploadSinature = async (imageBase64: string) => {
      var formData = new FormData();
      const blob: any = await convertBase64ToBlob(imageBase64);
      const file = new File([blob], "avatar.png", { type: "image/png" });
      formData.append('img', file);
      return await store.dispatch("dashboard/uploadSignature", formData)
    }
    watch(
      actionTimeSheet,
      async (newValue) => {
        if(newValue == Actions.SAVE.toString() || newValue == Actions.UPDATE.toString()) {
          const dataSubmit = {
            ... _.omit(taskInfo.value,['isCustomerAcceptance,isMechanicalAcceptance']),
            taskType: TASK_TYPES.JobCost,
            taskId: props.data?.taskId,
            valid: true,
          }
          
          store.commit("SET_LOADING", true);
          if (signatureCustomerTemp.value) {
            const signatureCustomerPath = await uploadSinature(signatureCustomer.value?.save('image/png'))
            dataSubmit.customerAcceptance = signatureCustomerPath.image
          }

          if (signatureMechanicalTemp.value) {
            const signatureMechanicalPath = await uploadSinature( signatureMechanical.value?.save('image/png'))
            dataSubmit.mechanicalAcceptance = signatureMechanicalPath.image
          }
          ctx.emit('submit', dataSubmit)
        }
        else if (newValue == Actions.CONFIRM.toString() || newValue == Actions.UPDATE_CONFIRM) {
          validFormData()
        }
      },
    )
    watch(props, () => {
      errors.value = props.errors
      taskInfo.value.startTime.hours = props?.data?.hours ?? 0;
      taskInfo.value.startTime.mins = props?.data?.mins ?? 0;
    });
    watch(taskInfo.value, () => {
      const valueStr = JSON.stringify(taskInfo.value)
      if (defaultValueStr === valueStr) {
        ctx.emit("changeTouched", false);
      } else {
        ctx.emit("changeTouched", true);
      }
    });
    return {
      formRef,
      rules,
      taskInfo,
      dropDownGlEntry,
      sigOption,
      signatureMechanical,
      signatureCustomer,
      allowUpdate,
      allowInputFiledService,
      taskId,
      handleInputTimeSheet,
      refTimeSheet,
      timeSheetStatus,
      errors,
      index: props?.data?.index,
      handleSignatureClick,
      handleClearSignature,
      isEdit: props?.isEdit
    };
  },
});
</script>
    <style scoped>
</style>